import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HOME_IMAGE, SS1, SS2, SS3, SS4, SS5, V1 } from "../../Assets/imageImport";
import { TextStrings } from "../../Assets/textStrings";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import Tilt from "react-parallax-tilt";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name">&nbsp;{TextStrings.fammap}</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <Tilt>
                <img
                  src={HOME_IMAGE}
                  alt="avatar"
                  className="img-fluid"
                  style={{ height: "450px", width: "450px", borderRadius: '50%' }}
                />
              </Tilt>
            </Col>
          </Row>
          <Row style={{ alignitems: 'center', paddingTop: 100, justifyContent: "center", }}>
            <Col md={3} style={{ padding: 20, display: "flex", alignItems: "center", justifyContent: "center", }}>
              <Tilt>
                <img
                  src={SS5}
                  alt="avatar"
                  className="img-fluid"
                  style={{ height: "450px", borderRadius: '5%' }}
                />
              </Tilt>
            </Col>
            <Col md={3} style={{ padding: 20, display: "flex", alignItems: "center", justifyContent: "center", }}>
              <Tilt>
                <img
                  src={SS4}
                  alt="avatar"
                  className="img-fluid"
                  style={{ height: "450px", borderRadius: '5%' }}
                />
              </Tilt>
            </Col>
            <Col md={3} style={{ padding: 20, display: "flex", alignItems: "center", justifyContent: "center", }}>
              <Tilt>
                <video
                  controls
                  loop
                  autoPlay muted
                  style={{ height: "450px", borderRadius: '5%' }}
                >
                  <source src={V1} type="video/mp4" />

                </video>
              </Tilt>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section >
  );
}

export default Home;
