import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import { HOME_IMAGE } from "../../Assets/imageImport";
import Tilt from "react-parallax-tilt";

function Privacy() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Privacy <strong className="purple">Policy</strong>
            </h1>

            <p className="home-about-body">
              FamMap is the newest application that provides seamless live location of your loved ones with no hassle and you can share this with anyone you want through out the globe :)
              <br />
              Spread smiles :D
              <br />
              <br />
              You should read our Privacy Policy in its entirety, but if you're short on time or want to keep it in mind, you can always look at this summary and video. We also invite you to explore the remaining sections of our privacy centre. It was created by us to provide you with concise summaries of our privacy practises. For instance, our Privacy by Product page breaks down the many privacy features for each of our products.
            </p>
            <br />
            <h1 style={{ fontSize: "1.8em" }}>
              Information we <span className="purple"> Collect </span>
            </h1>
            <p className="home-about-body">
              We obtain information from you when you engage with our services. For instance, creating an account is a requirement for many of our services, so we may need to gather some crucial information about you, such your name, username, password, email address, phone number, and date of birth. We might also ask you for some other details that will be displayed publicly on our services, like a profile photo or Bitmoji image. You might be required to give us your debit or credit card number and the account information that it is associated with in order to use some services, such as commerce products.
              <br />
            </p>
            <br />
            <h1 style={{ fontSize: "1.8em" }}>
              How we  <span className="purple"> use </span> Information
            </h1>
            <p className="home-about-body">
              How do we use the data that we gather?  The gist of the response is to give you a fantastic range of goods and services that we never stop enhancing. We accomplish that in the following ways:

              Our products and services are developed, run, enhanced, delivered, maintained, and protected.
              send you communications, including via SMS or email, as allowed. To react to support requests or to give details about our offerings that we believe you might find interesting, for instance, we might utilise email or SMS.
              keep track of, and examine, usage patterns.
              <br />
            </p>
            <br />
            <h1 style={{ fontSize: "1.8em" }}>
              How we  <span className="purple"> share </span> Information
            </h1>
            <p className="home-about-body">
              We share your location after your approval by adding other users as friend information about you in the following ways:
              <br />
              With other Fammap users. We may share the following information with other Fammap users:
              <br />
              information about you, such as your username, name, and location.
              <br />information about how you have interacted with our services, such as your  Fammap “score,” the names of Fammap users you are friends with, how close you are with your friends on  Fammap, your recent location history (if you choose to share your location on Fammap Map), and other information that will help Fammap users understand your connections with others using our services. For example, because it may not be clear whether a new friend request comes from someone you actually know, we may share whether you and the requestor have  Fammap friends in common.
              <br />information about your device, such as the operating system and device type, to help you receive Chats, Fammaps, and other content in the optimal viewing format.
              <br />any additional information you have directed us to share. For example, Fammap will share your information when you connect your  Fammap account to a third-party app, and if you share information or content from  Fammap to the third-party app.
            </p>
            <br />
            <h1 style={{ fontSize: "1.8em" }}>
              Control over   <span className="purple"> your </span> Information
            </h1>
            <p className="home-about-body">
              you can just drop email with your account details for account deletion and you account and information will be deleted after verification
              <br />
              email : <span className="purple"> fammaphelp@gmail.com </span>
            </p>
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <Tilt>
              <img src={HOME_IMAGE} alt="about"
                style={{ height: "450px", width: "450px", borderRadius: '50%' }}
                className="img-fluid" />
            </Tilt>

          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Privacy;
