import React from "react";
import Typewriter from "typewriter-effect";
import { TextStrings } from "../../Assets/textStrings";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          TextStrings.qoute1,
          TextStrings.qoute2,
          TextStrings.qoute3,
          TextStrings.qoute4,
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
